import React from "react";
import WorkItem from "../../Components/WorkItem/WorkItem";

function SoftwareDesign() {
  return (
    <section className="work-software-design bg-green">
      <h1 className="work-section-header">SOFTWARE DESIGN</h1>
      <div className="work-content">
        <WorkItem
          path={"images/programmingLanguages.png"}
          alt={"Landing page of Safe Space"}
          header={"PROGRAMMING LANGUAGES"}
          description={
            "As a Software Design student at the IT-University of Copenhagen, I developed skills in various programming languages, including Java, JavaScript, R, Python, F#, React, etc. Through learning and understanding different syntaxes, I now have the skill to adapt quickly to new programming environments and frameworks. This versatility enables me to approach challenges with a well-rounded toolkit, whether it's building interactive front-ends, optimizing data-driven algorithms, or implementing complex backend solutions. I also prioritize clean, maintainable code that aligns with both user needs and project goals."
          }
        />
        <WorkItem
          path={"images/algorithmDesign.png"}
          alt={"Landing page of zoenattestad.dk"}
          header={"ALGORITHM DESIGN"}
          description={
            "My master's thesis focuses on improving clustering algorithms, specifically Density-Peaks Clustering (DPC), by enhancing data partitioning through Locality-Sensitive Hashing (LSH). I developed three algorithms — LSH-DPC, LSH-DPC+, and A-LSH-DPC — each offering unique ways to reduce computational load while maintaining clustering accuracy. Our findings highlighted the potential of LSH-based clustering but also revealed parameter sensitivities impacting stability. The algorithm was implemented in Java. Have a read!"
          }
          direction={
            "https://www.linkedin.com/in/jonas-pedersen-879827327/overlay/1725883669375/single-media-viewer/?profileId=ACoAAFKGph0BwYW2zxSRpcQhm9mNGvGCY-mWksk"
          }
          cursorClass={"withCursor"}
        />
      </div>
    </section>
  );
}

export default SoftwareDesign;
